/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

html {
    /* Fixes background moving when an overlay is open */
    /* Turns out this breaks other things, who'd have thought */
    /* height: 100%; */
}

compact-layout>fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact.fuse-vertical-navigation-opened {
    width: 130px;
    min-width: 130px;
    max-width: 130px;
}

/* Fixes wonky animations when navigation from imprint to any other page */
fuse-vertical-navigation.fuse-vertical-navigation-animations-enabled {
    transition: none !important;
}

.service-image-container svg * {
    --color-primary: var(--fuse-primary);
    --color-secondary: black;
    --color-start: var(--fuse-primary);
    --color-end: var(--fuse-primary-900);
}

.service-image-container svg .color-secondary {
    fill: var(--color-secondary);
}

.service-image-container linearGradient stop:nth-child(1) {
    stop-color: var(--color-start) !important;
}

.service-image-container linearGradient stop:nth-child(2) {
    stop-color: var(--color-end) !important;
}

.dark .mat-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-warn-rgb), var(--tw-border-opacity)) !important;
}

.mdc-tooltip {
    white-space: pre-line;
}

.line-broken-tooltip {
    white-space: pre-line;
}

.tooltip-align-left>div {
    text-align: left !important;
}

.tooltip-align-center>div {
    text-align: center !important;
}

.country-flag {
    filter: drop-shadow(0px 0px 0.5px #888);
}

.mat-mdc-form-field {
    &.fuse-mat-no-subscript {
        .mat-mdc-form-field-subscript-wrapper {
            display: none !important;
            height: 0 !important;
        }
    }
}

.fuse-vertical-navigation-item-badge {
    top: 8px !important;
    right: 8px !important;
    left: auto !important;
}

button.mat-button-toggle-button {
    font-weight: 400!important;
}

.cc-window.cc-floating {
    max-width: 100%!important;
    width: 100%;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

div.intercom-launcher {
    transition: all 0.3s ease-in-out;
}

div.intercom-dfosxs {
    transition: all 0.3s ease-in-out;
}

body.audio-player-open div.intercom-launcher {
    bottom: 90px;
}

body.audio-player-open div.intercom-dfosxs {
    bottom: 90px;
}

body:not(.audio-player-open) div.intercom-launcher {
    bottom: 20px;
}

body:not(.audio-player-open) div.intercom-dfosxs {
    bottom: 20px;
}
